// https://getbootstrap.com/docs/4.1/getting-started/theming/

$theme-colors: (
  "primary": #007f95,
  "light": #edf6f8,
  "secondary": #80bfca
);


$font-family-sans-serif: "Helvetica Neue",
  sans-serif;

@font-face {
  font-family: "Helvetica Neue";
  src: url("/assets/fonts/HelveticaNeueLTPro-Md.woff");
  font-weight: bold;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("/assets/fonts/HelveticaNeueLTPro-Lt.woff");
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("/assets/fonts/HelveticaNeueLTPro-Lt.woff");
  font-weight: italic;
}

@font-face {
  font-family: "Helvetica Neue";
  src: url("/assets/fonts/HelveticaNeueLTPro-Lt.woff");
  font-weight: oblique;
}

$fa-font-path: "/node_modules/@fortawesome/fontawesome-free/webfonts";

@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "bootstrap/scss/bootstrap.scss";

html {
  // fix the hopping scrollbar
  overflow-y: scroll;
}

body {
  background-color: theme-color("light");
  color: theme-color("primary");
  font-size: 1.125rem;
}

.fa.pointer:hover {
  color: $link-hover-color;
}

img {
  height: auto;
  max-width: 100%;
}

.media {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1rem;

  .card-text {
    word-break: break-all;
  }

  .card-subtext {
    font-size: 0.8rem;
  }

  .card-img-top {
    max-height: 15rem;
    object-fit: contain;
    flex: 1 1 100%;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .card-icon-top {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .input-group {
    width: 25rem;
    justify-content: space-between;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: 1rem;
  }
}

.gallery {
  .list-header {
    max-width: 25rem;
  }
}

.navbar-brand img {
  height: 2.5rem;
}

.nav-item {
  padding-left: 2.5rem;
  text-transform: uppercase;
}

h1 {
  margin-bottom: 1rem;
  font-size: 1.875rem;
  font-weight: bold;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.transition-slow {
  transition: all 0.5s;
}

.elm-form-group {
  width: 100%;
  display: flex;
  flex-direction: row;

  >* {
    flex-grow: 1;
    flex-basis: 0;
    align-self: flex-end;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}

.flatpickr-input[readonly]:not(:disabled) {
  background-color: white;
}

.table-fit {
  width: auto;
}

.no-gutter>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.after-confirmation-msg-modal {
  .modal-header {
    flex-direction: row-reverse;
  }
}

.continuous-information-container {
  max-width: 25rem;
}

.checkbox-label {
  padding-left: 2rem;
}

.show-church-image-container,
.groundplan-image-container {
  max-height: 30rem;
  width: 100%;
  display: flex;

  img {
    object-fit: contain;
  }
}

#church-groundplan,
#map {
  height: 30rem;
  width: 100%;
  background-color: white;
  border: 1px solid theme-color("secondary");
}

.leaflet-groundplan-image {
  object-fit: contain;
}

input.btn,
button.btn {
  display: block;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  border-width: 2px;
}

form,
div.formlike {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;

  h1 {
    margin-bottom: 3rem;
    text-align: center;
  }

  label {
    margin-bottom: 0.125rem;
  }

  .btn {
    padding: 1rem 2rem;
  }

  .btn-sm-round {
    padding: 0.25rem 1rem;
    border-radius: 50px;
    text-transform: none;
  }

  small {
    font-size: 0.625rem;
    text-transform: none;
    font-weight: normal;
  }

  label+small {
    margin-left: 2rem;
  }
}

input.form-control,
input.form-control:focus,
.input-group-text,
.card {
  color: inherit;
  border-color: theme-color("secondary");
  background-color: white;
  box-shadow: none;
}

.input-group-text+.input-group-text {
  border-left-style: none;
}

input.form-control::placeholder {
  color: theme-color("secondary");
}

.input-group:focus-within {
  box-shadow: 0 0 0 0.2rem rgba(0, 127, 149, 0.25);
  border-radius: 0.25rem;
}

.no-border-right {
  border-right-style: none;
}

.login {
  button {
    margin: 0 auto;
  }

  small {
    margin-top: 1rem;
    text-align: center;
    display: block;
  }

  a:hover {
    color: inherit;
    text-decoration: inherit;
  }
}

.alert-container {
  position: absolute;
  bottom: 0;
  left: 1rem;
}

.pointer {
  cursor: pointer;
}

.list {
  .fa {
    color: theme-color("secondary");
  }

  .list-selection {
    background-color: theme-color("secondary");
    color: white;

    .fa {
      color: white;
    }
  }

  .card+.card {
    margin-top: -1px;
  }
}

.details {
  .label {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  button {
    border-width: 0.125rem;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
  }

  .btn-sm {
    padding: 0.25rem 0.75rem;
    font-size: 0.625rem;
    margin-left: auto;
    margin-bottom: 0;
  }

  hr {
    margin-top: 0.75rem;
    margin-bottom: 1rem;
    border-top: 0.0625rem solid theme-color("secondary");
  }
}

.divider {
  border-left: 0.0625rem solid theme-color("secondary");
}

.form-header {
  padding-top: 1.1875rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.0625rem solid theme-color("secondary");
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: theme-color("light");

  &.content-editor {
    justify-content: space-between;
  }
}

.list-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap-reverse;

  h1 {
    margin-bottom: 0;
  }
}

.content-editor-header {
  background-color: theme-color("primary");
  color: white;
}

.upload-drop-zone {
  background-color: white;
  height: 50vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  &.hover {
    border-color: theme-color("primary");

    .cloud-icon {
      opacity: 1;
    }
  }

  .cloud-icon {
    height: 100%;
    opacity: 0.3;
    transition: all 0.5s;
  }
}

.upload-history-item {
  margin-left: 3rem;
  height: 5rem;
  display: flex;

  div {
    display: flex;
    align-items: center;
  }

  img,
  .fa {
    height: 3rem;
    width: 3rem;
    object-fit: contain;
    margin-right: 1rem;
  }
}

.media-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.media-overlay-content {
  position: fixed;
  top: 10%;
  left: 15%;
  right: 15%;
  bottom: 10%;
  background-color: theme-color("light");
  z-index: 1100;
  display: flex;
  flex-direction: column;
  border: 1px solid theme-color("darker-light");
}

.media-overlay-content-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-height: 100%;
}

.media-overlay-content-audio-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  audio {
    width: 80%;
  }

  .fa {
    font-size: 15rem;
    margin-bottom: 5rem;
  }
}

.min-heigth-0 {
  min-height: 0;
}

.media-overlay-content-video-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 0;

  video {
    max-width: 80%;
  }
}

.media-overlay-data-item {
  line-height: 1;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: baseline;

  label {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    margin-right: 1rem;
  }
}

.media-overlay-icon-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 2.25rem;
  padding-bottom: 1rem;
}

.content-editor-av-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  audio,
  video {
    width: 80%;
  }

  .fa {
    margin-bottom: 1rem;
  }

  padding-top: 1rem;
  padding-bottom: 1rem;
}

.content-editor-image-collapsed {
  max-height: 7rem;
  max-width: 8rem;
  display: flex;

  img {
    object-fit: contain;
  }
}

//Slider
.switch {
  position: relative;
  display: block;
  width: 100px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: theme-color("secondary");
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: theme-color("primary");
}

input:focus+.slider {
  box-shadow: 0 0 1px theme-color("primary");
}

input:checked+.slider:before {
  -webkit-transform: translateX(66px);
  -ms-transform: translateX(66px);
  transform: translateX(66px);
}

nav {
  .pagination {
    margin-left: -0.5rem;
    flex-wrap: wrap;
  }

  .page-link {
    border-color: theme-color("darker-light");
    border-radius: calc(0.25rem - 1px);
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &:hover {
      background-color: white;
      border-color: theme-color("primary");
      color: theme-color("primary");
      cursor: pointer;
    }

    &.disabled,
    &.disabled:hover {
      border-color: lightgrey;
      color: lightgrey;
      cursor: default;
    }

    &.active {
      font-weight: bold;
      border-color: theme-color("primary");
    }
  }
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

//Html Editor

.pell-actionbar {
  background-color: theme-color("primary");

  .pell-button {
    color: white;

    b {
      display: flex;
      align-items: baseline;
    }
  }

  .pell-button-selected {
    background-color: theme-color("primary");
  }
}

.pell-content {
  height: auto;
  min-height: 2.75rem;
}

.pell-content,
.elph-editor__content-collapsed {
  /* add frontend-design-stuff here, so editor looks as close to the result as possible */
  text-transform: none;
  color: black;
  font-weight: normal;

  blockquote {
    background: theme-color("light");
    margin-left: 1rem;
    padding: 0.5rem 2rem;
  }
}

.elph-editor__content-collapsed {
  /* 10px is pells padding */
  padding: 10px;
  max-height: 8rem;
  overflow: hidden;
}

.elph-editor__content-collapsed:after {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% - 11px);
  height: 50%;
  bottom: 0;
  background: -webkit-linear-gradient(transparent, #fff) left repeat;
  background: linear-gradient(transparent, #fff) left repeat;
}

.elph-editor-raw {
  width: 100%;
}

.church-list-icon-container {
  min-width: 4rem;
}

sub {
  color: dimgray;
}

.img-thumbnail-75 {
  max-width: 75px;
  max-height: 75px;
}

#__parcel__error__overlay__ {
  &>div {
    white-space: pre;
    overflow: scroll;
  }
}
